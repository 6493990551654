<template lang="pug">
.inner-industry-tree
  cleanable-title(v-if="mode === 'dialog'" @reset="cleanFilter")
    .no-transform Выбранные отрасли
  tag-input.tag-input(
    v-if="mode === 'dialog'"
    :model-value="checkedIndustries"
    placeholder="Отрасли не выбраны"
    @delete="onDeleteTag"
  )

  .title(v-if="mode !== 'dialog'")
    ui-radio-button(v-model="operatorModel" :options="operatorOptions")
    cleanable-title.industry-title(@reset="cleanFilter")
      span ОТРАСЛЬ
      template(v-if="mode !== 'dialog'" v-slot:additional)
        ui-warning(placement="bottom-end")
          .warning-content
            span.label Модификатор И-ИЛИ
            ul
              li В положении <b>И</b> поиск будет осуществляться и по <b>ключевым словам</b>, и по выбранным <b>отраслям</b>
              li В положении <b>ИЛИ</b> результаты поиска будут содержать результаты, которые принадлежат выбранным <b>отраслям</b> ИЛИ в которых встречаются введенные <b>ключевые слова</b>

  base-input.filter-card_input(
    clearable
    v-model="filterText"
    placeholder="Введите название отрасли или выберите из списка"
    icon-tooltip="Полный список отраслей"
    :loader="request?.loader"
    :icon="mode === 'form' ? UiIconNames.Icon_List : ''"
    @click-button="$emit('open:dialog')"
    @update:model-value="onFilter"
  )
  .tree-wrapper(:class="mode === 'form' ? '_default' : '_dialog'")
    el-tree(
      ref="industriesTree"
      show-checkbox
      expand-on-click-node
      node-key="id"
      :data="industriesData"
      :props="{ children: 'children', label: 'title', class: customNodeClass }"
      :default-checked-keys="modelValue"
      :filter-node-method="filterClassification"
      @check="check"
      @node-expand="nodeExpand"
    )
</template>

<script lang="ts">
import { defineComponent, watch, ref, computed } from "vue";
import { useDebounceFn, useVModel } from "@vueuse/core";
import { useAbort, useApi } from "~/use/api/useApi";

import type { ElTree } from 'element-plus'
import type { PropType } from "vue";
import type { IndustryI } from "@/stores/manuals/ManualsInterface";
import type { Ref } from "vue";

import TagInput from "@/components/tagInput/TagInput.vue";
import BaseInput from "@/components/ui/base/BaseInput.vue";
import CleanableTitle from "@/components/searchForm/elements/CleanableTitle.vue";
import useManualsStore from "@/stores/manuals/useManualsStore";
import UiIconNames from "@/components/ui/icon/UiIconNames";
import UiRadioButton from "~/components/ui/radio/UiRadioButton.vue";
import UiWarning from "~/components/ui/tooltip/UiWarning.vue";

interface Tree {
  id: number;
  sort: number;
  title: string;
  children: Tree[] | undefined;
}

function getFilteredIndustries(request: Ref<any>, word: string) {

  request.value.loader = true
  const { signal, abort, } = useAbort();

  if (request.value.info) request.value.info.cancel();
  request.value.info = { cancel: abort }

  return new Promise((resolve, reject) => {
    useApi().filtered.fetchFilteredIndustries<any[]>({ wordSearch: { keywords: [word] }}, signal)
      .then((response) => {
        request.value.info = null
        request.value.loader = false
        resolve(response || [])
      })
      .catch(() => {
        if (!signal.aborted) {
          request.value.info = null
          request.value.loader = false
          resolve([])
        }
      })
  })
}

function getAllChildrenKeys(res: number[], node: IndustryI) {
  if (!node) return;
  const children = node.children || [];
  res.push(node.id)
  if (!children?.length) return;
  children.forEach(c => getAllChildrenKeys(res, c));
  return res;
}

export default defineComponent({
  name: "InnerIndustryTree",
  components: {
    UiWarning,
    UiRadioButton,
    TagInput,
    BaseInput,
    CleanableTitle,
  },
  props: {
    mode: {
      type: String as PropType<'dialog'|'form'>,
      default: 'form',
    },
    modelValue: {
      type: Array as PropType<number[]>,
      default: () => [],
    },
    operatorMode: {
      type: Number,
      default: 2,
    },
  },
  emits: [
    'reset',
    'update:modelValue',
    'update:operatorMode',
    'open:dialog',
  ],
  setup(props, context) {

    const operatorOptions = [
      { id: 'and', label: 'И', value: 1 },
      { id: 'or', label: 'ИЛИ', value: 2 },
    ]

    const manualsStore = useManualsStore()
    const industriesData = computed(() => manualsStore.industries.map(e => { return { ...e, firstLeaf: true }} ))

    const filterText = ref("");
    const industriesTree = ref<InstanceType<typeof ElTree>>();

    const request = ref<any>({
      loader: false,
      info: null,
    })

    const industryIds = useVModel(props, 'modelValue', context.emit)
    const operatorModel = useVModel(props, 'operatorMode', context.emit)

    const checkedIndustries = computed(() => {
      const all = industriesTree.value?.getCheckedNodes(false, false) || []
      const children = all.reduce((res, item) => {
        res.push(...item.children);
        return res;
      }, []);

      return all.filter(item => !children.includes(item));
    })

    const filteredDataKeys = ref<number[]>([]);              // отфильтрованные отрасли, которые пришли с бека

    watch(industryIds, (val: Array<number>) => {
      if (industriesTree.value) industriesTree.value.setCheckedKeys(val || [], false);
    }, { deep: true });

    const customNodeClass = (data: Tree) => {
      const res = []
      if (data.firstLeaf) res.push("first-leaf");
      if (data.children?.length && !data.firstLeaf) res.push("inner-leaf");
      if (filteredDataKeys.value.includes(data.id)) res.push("highlight-leaf");
      return res.length ? res : null;
    };

    function cleanFilter() {
      filterText.value = ''
      filteredDataKeys.value = []
      filterTree([])
      industryIds.value = []
      if (industriesTree.value) industriesTree.value.setCheckedKeys([], false)
    }

    function showTreeNodes(keys: number[]) {
      try {
        keys.forEach((elem: number) => {
          const node = industriesTree.value?.getNode(elem)
          if (node) node.visible = true
        })
      } catch {}
    }

    function nodeExpand(node: any) {
      if (filterText.value) {
        const childrenIds = node?.children?.map((e: any) => e.id) || []
        const needShowKeys = childrenIds.filter(e => !filteredDataKeys.value.includes(e))
        if (childrenIds.length && needShowKeys.length && industriesTree.value) showTreeNodes(needShowKeys)
      }
    }

    function check(node: any, nodeStatus: any) {
      const nodeKey = node?.id
      const allKeys = nodeStatus?.checkedKeys as number[] || []

      // check срабатывает на любой клик по чекбоксу, поэтому проверяем, была ли выбрана или удалена нода
      if (filterText.value && nodeKey && allKeys.includes(nodeKey)) {
        const newKeys = allKeys.filter(e => !industryIds.value.includes(e))
        if (newKeys.length) showTreeNodes(newKeys)
      }

      industryIds.value = allKeys;
    }

    function onDeleteTag(tag: IndustryI) {
      if (tag.children?.length) {
        const res = getAllChildrenKeys([], tag)
        industryIds.value = industryIds.value.filter(e => !res.includes(e))
      } else {
        const index = industryIds.value.indexOf(tag.id);
        if (index > -1) industryIds.value.splice(index, 1);
      }
    }

    /** FILTER TREE INFO */

    /** принудительная фильтрация дерева */
    function filterTree(keys: number[]) {
      if (industriesTree.value) industriesTree.value.filter(keys);
    }

    /** функция, определяющая подходит ли нода под условие фильтрации
     * m - отданные беком данные
     * data - конкретная нода
     */
    function filterClassification(keys: number[], data: Tree) {
      if (!keys.length) return !filterText.value;
      return keys.includes(data.id);
    }

    function getData(word: string) {
      if (!word) {
        filteredDataKeys.value = []
        filterTree([])
      } else {
        getFilteredIndustries(request, word)
          .then((result: any[]) => {
            filteredDataKeys.value = result.map(e => e.id) as number[] || []
            filterTree(filteredDataKeys.value)
          })
      }
    }

    const onFilter = useDebounceFn(getData, 200);

    return {
      operatorOptions,
      operatorModel,
      request,
      filterText,
      industriesData,
      industriesTree,
      checkedIndustries,
      onFilter,
      check,
      nodeExpand,
      cleanFilter,
      customNodeClass,
      filterClassification,
      onDeleteTag,
      UiIconNames,
      industryIds,
      filteredDataKeys,
    };
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixin/searchForm";

.no-transform {
  text-transform: none;
}

.tag-input {
  height: 140px !important;
  margin-bottom: 16px;
  box-shadow: none !important;
  border-color: var(--input-border-color) !important;
}

.tree-wrapper._default {
  @import "@/assets/styles/searchForm/searchFormTree";

  @include tree-wrapper;
}

.tree-wrapper._dialog {
  @import "@/assets/styles/searchForm/searchFormTree";

  @include tree-wrapper;
  height: 320px;
}

.filter-card_input {
  @include filter-card-input;
}

.title {
  display: flex;
  flex-flow: row;
  gap: 16px;
  margin-bottom: 8px;
  align-items: center;

  .industry-title {
    margin-bottom: 0;
    width: 100%;
  }
}

.warning-content {
  display: flex;
  flex-flow: column;
  gap: 6px;
  max-width: 420px;
  padding: 4px;

  .label {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }
}
</style>
