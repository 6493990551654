<template lang="pug">
.industry-tree
  inner-industry-tree(v-model="modelValue.industryIds" v-model:operatorMode="modelValue.industrySearchMode" @open:dialog="openDialog" @reset="$emit('reset', 'industryIds')")
  industry-dialog(v-model:show="showIndustryDialog" v-model:industryIds="modelValue.industryIds")
  warning-message.info(v-if="showIndustryMessage" type="warning") {{ industryMessage }}
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useFiltersWarnings } from "~/use/search/useSearchWarnings";
import { useVModel } from "@vueuse/core";

import type { PropType } from "vue";
import type { BaseSearchFormInterface } from "@/stores/search/SearchFormInterface";

import IndustryDialog from "@/components/searchForm/dialogs/IndustryDialog.vue";
import InnerIndustryTree from "@/components/searchForm/elements/InnerIndustryTree.vue";
import WarningMessage from "~/components/ui/alert/WarningMessage.vue";

export default defineComponent({
  name: "IndustryTree",
  components: {
    WarningMessage,
    IndustryDialog,
    InnerIndustryTree,
  },
  props: {
    modelValue: {
      type: Object as PropType<BaseSearchFormInterface>,
      default: () => ({}),
    },
    operatorMode: {
      type: Number,
      default: 2,
    },
  },
  emits: [
    'reset',
    'update:modelValue',
    'update:operatorMode',
  ],
  setup(props, context) {

    const showIndustryDialog = ref(false);
    const searchFormModel = useVModel(props, 'modelValue', context.emit);

    const operatorModel = useVModel(props, 'operatorMode', context.emit);

    function openDialog() {
      showIndustryDialog.value = true;
    }

    const { industryMessage, showIndustryMessage } = useFiltersWarnings(searchFormModel);

    return {
      operatorModel,
      industryMessage,
      showIndustryMessage,
      showIndustryDialog,
      openDialog,
    };
  },
});
</script>

<style scoped lang="scss">
.industry-tree {
  width: 100%;
  display: flex;
  flex-flow: column;
  height: 100%;
  contain: size;

  :deep(.tree-wrapper) {
    height: 100%;
  }

  :deep(.inner-industry-tree) {
    display: contents;
    height: 100%;
  }
}

.info {
  margin-top: 12px;
}
</style>
