<template lang="pug">
.etp-filter
  cleanable-title(@reset="$emit('reset', 'govRuIds')")
    span Номер закупки
  tag-input.etp-input(
    writable
    v-model="modelValue.govRuIds"
    placeholder="Введите номера закупок (через запятую)"
  )
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';
import CleanableTitle from "~/components/searchForm/elements/CleanableTitle.vue";
import TagInput from "~/components/tagInput/TagInput.vue";
import type { BaseSearchFormInterface } from "~/stores/search/SearchFormInterface";

export default defineComponent({
  name: "EtpNumber",
  components: {
    TagInput,
    CleanableTitle,
  },
  emits: [
    'reset',
    'update:modelValue',
  ],
  props: {
    modelValue: {
      type: Object as PropType<BaseSearchFormInterface>,
      default: () => ({}),
    },
  },
})
</script>

<style scoped lang="scss">
.etp-filter {
  display: flex;
  flex-flow: column;

  .etp-input {
    height: auto !important;
    min-height: 40px !important;
    max-height: 120px;
  }
}
</style>
