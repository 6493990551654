<template lang="pug">
.advanced_search(:class="gridView && 'grid-view'")
  .advanced-search-content(
    v-for="card of cards"
    :key="card"
    :class="panelsList[card].className"
  )
    component(
      v-for="component of panelsList[card].components"
      :is="component"
      v-model="modelValue"
      @reset="$emit('reset', $event)"
    )
</template>

<script lang="ts">
import { defineComponent } from "vue";

import KeywordsForm from "@/components/searchForm/filters/KeywordsForm.vue";
import ByIndustryForm from "@/components/searchForm/filters/IndustryTree.vue";
import RegionsTree from "@/components/searchForm/filters/RegionsTree.vue";
import Documentation from "@/components/searchForm/filters/Documentation.vue";
import EtpNumber from "~/components/searchForm/filters/EtpNumber.vue";

import SitesWrapper from "@/components/searchForm/filters/SitesWrapper.vue";
import OkpdWrapper from "@/components/searchForm/filters/OkpdWrapper.vue";
import SuppliersWrapper from "@/components/searchForm/filters/SuppliersWrapper.vue";
import CustomersWrapper from "@/components/searchForm/filters/CustomersWrapper.vue";

import DatePublic from "@/components/searchForm/filters/DatePublic.vue";
import DateOpen from "@/components/searchForm/filters/DateOpen.vue";

import DeliveryPlace from "@/components/searchForm/filters/DeliveryPlace.vue";
import TypeLot from "@/components/searchForm/filters/TypeLot.vue";
import LotStatus from "@/components/searchForm/filters/LotStatus.vue";
import LotZMO from "@/components/searchForm/filters/LotZMO.vue";
import Cost from "@/components/searchForm/filters/Cost.vue";
import SmpSonko from "@/components/searchForm/filters/SmpSonko.vue";
import Laws from "@/components/searchForm/filters/Laws.vue";
import Kpp from "@/components/searchForm/filters/Kpp.vue";

import type { PropType } from "vue";
import type { BaseSearchFormInterface } from "@/stores/search/SearchFormInterface";

interface CardPanel {
  [key: string]: {
    title?: string;
    components: Array<string>;
    className?: string;
  };
}

export default defineComponent({
  name: "AdvancedSearch",
  components: {
    KeywordsForm,
    ByIndustryForm,
    RegionsTree,
    Documentation,

    OkpdWrapper,
    CustomersWrapper,
    SuppliersWrapper,
    SitesWrapper,

    DatePublic,
    DateOpen,
    DeliveryPlace,

    Cost,
    Kpp,
    Laws,
    TypeLot,
    LotStatus,
    LotZMO,
    SmpSonko,
    EtpNumber,
  },
  emits: [
    "reset",
  ],
  props: {
    cards: {
      type: Array,
      default: () => [],
    },
    gridView: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Object as PropType<BaseSearchFormInterface>,
      default: () => ({}),
    },
  },
  setup() {

    const panelsList: CardPanel = {
      KeywordsAndIndustry: {
        components: ["KeywordsForm", "ByIndustryForm"],
        className: 'industry-block',
      },
      KeywordsAndIndustryFull: {
        components: ["EtpNumber", "KeywordsForm", "ByIndustryForm"],
        className: 'full-words-block',
      },
      RegionsAndLaws: {
        components: ["RegionsTree", "Laws"],
      },
      SitesCostDocs: {
        components: ["SitesWrapper", "Cost", "Documentation"],
        className: 'grid-layout',
      },
      RegionsAndCost: {
        components: ["RegionsTree", "Cost"],
      },
      DatePublic: {
        components: ["DatePublic"],
      },
      DatePublicAndKpp: {
        components: ["DatePublic", "Kpp"],
      },
      DatesAndOkpd: {
        components: ["DatePublic", "DateOpen", "OkpdWrapper"],
        className: 'dates-and-okpd',
      },
      StatusAndTypeLot: {
        components: ["TypeLot", "LotStatus", "LotZMO", "SmpSonko"],
        className: 'status-and-type',
      },
      TypeLotAndLaws: {
        components: ["TypeLot", "Laws", "SmpSonko"],
        className: 'status-and-sonko',
      },
      DeliveryPlaces: {
        components: ["DeliveryPlace"],
      },
      OkpdAndSites: {
        components: ["SitesWrapper", "OkpdWrapper"],
        className: 'in-column',
      },
      OkpdAndCustomers: {
        components: ["OkpdWrapper", "CustomersWrapper"],
        className: 'in-column',
      },
      OkpdAndSuppliers: {
        components: ["OkpdWrapper", "SuppliersWrapper"],
        className: 'in-column',
      },
      CustomersAndSuppliers: {
        components: ["CustomersWrapper", "SuppliersWrapper"],
      },
    };

    return {
      panelsList,
    };
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixin/main";
@import "@/assets/styles/mixin/searchForm";

.advanced_search {
  display: flex;
  flex-flow: column;
  gap: 32px;

  &.grid-view {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);   // prevents overflow caused 1fr 1fr
    column-gap: 46px;
    row-gap: 32px;
  }

  .advanced-search-content {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);   // prevents overflow caused 1fr 1fr
    column-gap: 46px;
    row-gap: 32px;
  }
}

.advanced_search {

  .full-words-block {
    grid-template-areas:  "etp-filter         industry-tree"
                          "keywords-filter    industry-tree";
    row-gap: 16px;

    ::v-deep(.etp-filter) {
      grid-area: etp-filter;
    }

    ::v-deep(.keywords-filter) {
      grid-area: keywords-filter;
    }

    ::v-deep(.industry-tree) {
      grid-area: industry-tree;
    }
  }

  .industry-block {
    display: grid;
  }

  .dates-and-okpd {
    grid-template-areas:  "date-public  okpd-wrapper"
                          "date-open    okpd-wrapper";

    ::v-deep(.date-public) {
      grid-area: date-public;
    }

    ::v-deep(.date-open) {
      grid-area: date-open;
    }

    ::v-deep(.category-okpd) {
      grid-area: okpd-wrapper;
    }
  }

  .status-and-type {
    grid-template-columns: 2fr repeat(3, 1fr);
    column-gap: 28px;
  }

  .status-and-sonko {
    display: flex;
    flex-flow: column;

    :deep(.smp-and-sonko) {
      .values {
        display: grid;
        grid-template-columns: minmax(0, 6fr) minmax(0, 4fr);
      }
    }

    :deep(.lot-type) {
      .filter-card__checkboxes {
        display: grid;
        grid-template-columns: minmax(0, 6fr) minmax(0, 4fr);
      }
    }
  }

  .in-column {
    display: flex;
    flex-flow: column;
  }

  .grid-layout {
    grid-template-areas:  "docs-words   sites"
                          "docs-words   cost";
    row-gap: 24px;

    ::v-deep(.documentation) {
      grid-area: docs-words;
      //margin-top: 30px;
    }

    ::v-deep(.price_and_payment_terms) {
      grid-area: cost;
    }

    ::v-deep(.lot_auction_sites) {
      grid-area: sites;
    }
  }
}
</style>
