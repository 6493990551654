import type { Ref } from "vue";
import type { BaseSearchFormInterface, TabMode } from "@/stores/search/SearchFormInterface";
import pick from "lodash/pick";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";

const SF: {[key: string]: string[]} = {
  Industries: ['industryIds'],
  Regions: ['places'],
  Sites: ['lotAuctionSitesIds', 'lotAuctionSitesGroups', 'withoutLotAuctionSite'],
  Laws: ['lotLawIds'],
  GovRuIds: ['govRuIds'],
  LotsSearch: ['lotsSearch.keywords', 'lotsSearch.exceptionWords'],
  ExpertSearch: ['lotsSearch.expertSearch'],
  Cost: ['cost', 'costApp'],
  DocsSearch: ['docsSearch'],
  Type: ['lotTypeShortIds'],
  Status: ['lotStatusIds'],
  Zmo: ['isZMO'],
  SmpSonko: ['notSmallBusiness'],
  DatePublic: ['datePublic'],
  DateApplication: ['dateApplication'],
  Okpd: ['okpdCodes'],
  DeliveryPlaceIds: ['deliveryPlaceIds'],
  DeliveryPlaceFullText: ['lotsSearch.deliveryPlace'],
  Customers: ['customerIds', 'needOrganizationResponsibleIds'],
  Suppliers: ['supplierIds', 'winner'],
  Kpp: ['kpp', 'needAllFilials'],
}

const searchTabs = [
  { name: 'Ключевые слова, Отрасли, Регионы, Законы', key: 'default', cards: ['KeywordsAndIndustryFull', 'RegionsAndLaws'],
    names: [SF.GovRuIds, SF.LotsSearch, SF.ExpertSearch, SF.Industries, SF.Regions, SF.Laws] },
  { name: 'Площадки, Цена, Документация', key: 'filters3', cards: ['SitesCostDocs'],
    names: [SF.Sites, SF.Cost, SF.DocsSearch ] },
  { name: 'Типы, Этапы, Даты, ОКПД, прочее', key: 'filters', cards: ['StatusAndTypeLot', 'DatesAndOkpd'],
    names: [SF.Type, SF.Status, SF.Zmo, SF.SmpSonko, SF.DatePublic, SF.DateApplication, SF.Okpd] },
  { name: 'Места поставки, Заказчики, Участники', key: 'filters2', cards: ['DeliveryPlaces', 'CustomersAndSuppliers'],
    names: [SF.DeliveryPlaceIds, SF.DeliveryPlaceFullText, SF.Customers, SF.Suppliers] },
]

const templateTabs = [
  { name: 'Ключевые слова, Отрасли, Регионы, Законы', key: 'default', cards: ['KeywordsAndIndustry', 'RegionsAndLaws'],
    names: [SF.LotsSearch, SF.ExpertSearch, SF.Industries, SF.Regions, SF.Laws] },
  { name: 'Площадки, Цена, Документация', key: 'filters3', cards: ['SitesCostDocs'],
    names: [SF.Sites, SF.Cost, SF.DocsSearch ] },
  { name: 'Типы, Этапы, Даты, ОКПД, прочее', key: 'filters', cards: ['StatusAndTypeLot', 'DatesAndOkpd'],
    names: [SF.Type, SF.Status, SF.Zmo, SF.SmpSonko, SF.DatePublic, SF.DateApplication, SF.Okpd] },
  { name: 'Места поставки, Заказчики, Участники', key: 'filters2', cards: ['DeliveryPlaces', 'CustomersAndSuppliers'],
    names: [SF.DeliveryPlaceIds, SF.DeliveryPlaceFullText, SF.Customers, SF.Suppliers] },
]

const industryTabs = [
  { name: 'Ключевые слова, Отрасли, Дата публикации', key: 'default', cards: ['KeywordsAndIndustry', 'DatePublic'],
    names: [SF.LotsSearch, SF.ExpertSearch, SF.Industries, SF.DatePublic] },
  { name: 'Регионы, Цена', key: 'keywords', cards: ['RegionsAndCost'],
    names: [SF.Regions, SF.Cost] },
  { name: 'Типы, Законы, Площадки, ОКПД, СМП и СОНКО', key: 'filters', cards: ['TypeLotAndLaws', 'OkpdAndSites'], isGridView: true,
    names: [SF.Type, SF.Laws, SF.SmpSonko, SF.Okpd, SF.Sites] },
  { name: 'Места поставки, Заказчики, Участники', key: 'advancedFilters', cards: ['DeliveryPlaces', 'CustomersAndSuppliers'],
    names: [SF.DeliveryPlaceIds, SF.DeliveryPlaceFullText, SF.Customers, SF.Suppliers] },
]

const suppliersTabs = [
  { name: 'Ключевые слова, Отрасли, Даты, КПП', key: 'default', cards: ['KeywordsAndIndustry', 'DatePublicAndKpp'],
    names: [SF.LotsSearch, SF.ExpertSearch, SF.Industries, SF.DatePublic, SF.Kpp] },
  { name: 'Регионы, Цена', key: 'filters1', cards: ['RegionsAndCost'],
    names: [SF.Regions, SF.Cost] },
  { name: 'Прочие фильтры', key: 'filters', cards: ['TypeLotAndLaws', 'OkpdAndCustomers'], isGridView: true,
    names: [SF.Type, SF.Laws, SF.SmpSonko, SF.Okpd, SF.Customers] },
]

const customersTabs = [
  { name: 'Ключевые слова, Отрасли, Даты, КПП', key: 'default', cards: ['KeywordsAndIndustry', 'DatePublicAndKpp'],
    names: [SF.LotsSearch, SF.ExpertSearch, SF.Industries, SF.DatePublic, SF.Kpp] },
  { name: 'Регионы, Цена', key: 'filters1', cards: ['RegionsAndCost'],
    names: [SF.Regions, SF.Cost] },
  { name: 'Прочие фильтры', key: 'filters', cards: ['TypeLotAndLaws', 'OkpdAndSuppliers'], isGridView: true,
    names: [SF.Type, SF.Laws, SF.SmpSonko, SF.Okpd, SF.Suppliers] },
]

function getTabs(t: TabMode, additional?: string) {
  switch (t) {
    case 'LotsListSearch': return additional === 'byTemplate' ? templateTabs : searchTabs;
    case 'AnalyseIndustry': return industryTabs;
    case 'AnalyseSuppliers': return suppliersTabs;
    case 'AnalyseCustomers': return customersTabs;
  }
}

export function useAdvancedFilters(tab: TabMode, additional?: string) {

  const tabs = getTabs(tab, additional);

  function prepareSF(names: string[][], searchForm: Ref<BaseSearchFormInterface>, defaultSearchForm: BaseSearchFormInterface) {
    return names.reduce((res: any, item: any) => {
      const form = cloneDeep(pick(searchForm.value, item))
      const defaultSF = pick(defaultSearchForm, item)

      for (const [key, value] of Object.entries(form)) {
        if (isEqual(value, defaultSF[key])) delete form[key];
      }

      res += Object.keys(form).length ? 1 : 0;
      return res;
    }, 0)
  }

  return {
    tabs,
    prepareSF,
  }
}
